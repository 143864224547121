[data-component="ApplicationFormGuarantors"] {

  .button-wrapper {

    display: flex;
    flex-direction: column;

    > button.circle   {

      > span {
        display: none;
      }

      @media(max-width: 640px) {
        width: unset;
        border-radius: 32px;
        align-self: flex-start;
        height: 2.5em;

        &::before {
          display: none;
        }

        > span {
          display: inline;
        }
      }
    }

    > span {
      border-radius: 30px;
    }

    &::before {
      content: "\00a0";

      @media(max-width: 640px) {
        display: none;
      }
    }
  }

  .guarantor-add-options {
    display: flex;
  }
}
