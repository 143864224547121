[data-component="tabs"] {

  display: flex;
  flex-direction: column;

  .tabs {
    display: flex;
  }

  [role="tab"] {

    position: relative;

    color: var(--text-gray-900);
    padding: 12px 32px;

    display: flex;
    align-items: center;
    align-content: center;

    outline: none;

    @apply text-400;

    &::after {
      content: '';

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;

      background-color: transparent;
      transition: height .3s ease;
    }

    &[aria-selected="true"] {
      &::after {
        height: 2px;
        background-color: var(--color-primary-500);
      }
    }
  }
}
